<template>
  <div class="mfa-setup">
    <LoadingHeart v-if="currentMfaState === MFA_STATES.LOADING" />
    <div v-else>
      <div v-if="currentMfaState === MFA_STATES.SETUP" class="mfa-qr-code">
        <h1>{{ $t('MFA.SETUP.HEADING') }}</h1>
        <p class="mfa-subheading">
          {{ $t('MFA.SETUP.SUBHEADING') }}
        </p>
        <h3>{{ $t('MFA.SETUP.QR.HEADING') }}</h3>
        <p class="scan-qr-code-subheading">
          {{ $t('MFA.SETUP.QR.SUBHEADING') }}
        </p>
        <SmartFaqLink v-if="faqLink" :link="faqLink" class="help-link" />
        <div class="qr-code-image">
          <img :src="qrCodeData" alt="" />
          <!-- <div class="unable-to-scan">
            <p>Unable to Scan?</p>
            <p>
              You can use the setup key to manually configure your authenticator
              app.
            </p>
            <button class="link link-main">Setup Key</button>
          </div> -->
        </div>
        <h3>{{ $t('MFA.SETUP.VERIFY.SUBHEADING') }}</h3>
        <div class="code-input" :class="{ 'has-error': setupError }">
          <input
            type="text"
            :value="otp"
            :placeholder="$t('MFA.SETUP.VERIFY.INPUT_PLACEHOLDER')"
            @input="setOtp"
          />
          <small v-if="setupError" class="error-info">{{
            $t(setupError)
          }}</small>
        </div>
        <div class="actions">
          <button class="btn btn-primary" @click="sendCode">
            {{ $t('MFA.SETUP.CONTINUE') }}
          </button>
          <button class="link link-main" @click="cancel">
            {{ $t('GENERAL.CANCEL') }}
          </button>
        </div>
      </div>
      <div
        v-if="currentMfaState === MFA_STATES.SETUP_FINISHED"
        class="mfa-recovery-codes"
      >
        <h1>{{ $t('MFA.RECOVERY_CODES.HEADING') }}</h1>
        <p class="mfa-subheading">
          {{ $t('MFA.RECOVERY_CODES.SUBHEADING') }}
        </p>
        <div class="info-box">
          <div>
            <Icon icon="attention" />
            <h3>{{ $t('MFA.RECOVERY_CODES.INFO.HEADING') }}</h3>
          </div>
          <p>
            {{ $t('MFA.RECOVERY_CODES.INFO.SUBHEADING') }}
          </p>
        </div>
        <ul>
          <li v-for="(code, index) in recoveryCodes">
            <p>Code {{ index + 1 }}:</p>
            <p>{{ code }}</p>
          </li>
        </ul>
        <div class="actions">
          <a
            class="btn btn-light icon-btn"
            :href="downloadText"
            :download="`${$t('MFA.RECOVERY_CODES.FILE_NAME')}.txt`"
            @click="download"
          >
            <Icon icon="download-file" />
            {{ $t('GENERAL.DOWNLOAD') }}
          </a>
        </div>
        <div class="actions">
          <button
            class="btn btn-primary icon-btn"
            :disabled="!downloaded"
            @click="$emit('onDone')"
          >
            <Icon icon="checklist" />
            {{ $t('MFA.RECOVERY_CODES.DONE') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingHeart from '@/LoadingHeart/LoadingHeart.vue';
import authService from '@/api/authService/authService';
import MFA_STATES from '@/states/mfaStates';
import smartFaqLinks from '@/smartFaq/smartFaqLinks';
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';
import analytics from '@/tracking/analytics';

export default {
  name: 'MfaSetup',
  components: { LoadingHeart, SmartFaqLink },
  emits: ['onCancel', 'onDone'],
  data() {
    return {
      MFA_STATES,
      currentMfaState: MFA_STATES.LOADING,
      qrCodeData: null,
      otp: null,
      recoveryCodes: [],
      downloaded: false,
      setupError: null,
      faqLink: smartFaqLinks.MFA,
    };
  },
  computed: {
    downloadText() {
      let text = '';
      this.recoveryCodes.map((recoveryCode, index) => {
        text = `${text}Code ${index + 1}: ${recoveryCode}\n`;
      });
      return `data:text/plain;charset=utf-8,${text}`;
    },
  },
  async created() {
    this.fetchQrCode();
    analytics.logEvent('mfa-setup-start');
  },
  methods: {
    async fetchQrCode() {
      const data = await authService.setupMfa();
      this.qrCodeData = data;
      this.currentMfaState = MFA_STATES.SETUP;
    },
    async sendCode() {
      try {
        analytics.logEvent('mfa-setup-code-input');
        const data = await authService.finishMfaSetupAndCreateRecoveryCodes(
          this.otp,
        );
        this.recoveryCodes = data.recoveryCodes;
        this.currentMfaState = MFA_STATES.SETUP_FINISHED;
        analytics.logEvent('mfa-setup-finished');
      } catch (error) {
        if (error?.data?.status === 'NOT_SET_UP') {
          this.setupError = 'MFA.SETUP.ERROR.OUTDATED';
          this.fetchQrCode();
          analytics.logEvent('mfa-setup-error-code');
        } else if (error?.data?.status === 'TOO_MANY_REQUESTS') {
          this.setupError = 'MFA.SETUP.ERROR.TOO_MANY_REQUESTS';
          analytics.logEvent('mfa-setup-error-requests');
        } else {
          this.setupError = 'MFA.SETUP.ERROR.INVALID_CODE';
          analytics.logEvent('mfa-setup-error-outdated');
        }
      }
    },
    setOtp(event) {
      this.setupError = false;
      this.otp = event.target.value;
    },
    download() {
      this.downloaded = true;
      analytics.logEvent('mfa-setup-download');
    },
    cancel() {
      this.$emit('onCancel');
      analytics.logEvent('mfa-setup-cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';
@import 'src/scss/styleguide/type';

.mfa-qr-code,
.mfa-recovery-codes {
  display: flex;
  flex-direction: column;

  h1 {
    margin: 0 0 8px 0;
  }

  .mfa-subheading {
    margin: 0 0 24px 0;
    color: $grey60;
  }

  .scan-qr-code-subheading {
    margin: 8px 0;
    color: $grey60;
  }

  .qr-code-image {
    display: flex;
    align-items: center;
    margin: 24px 0;

    img {
      border: $grey15 1px solid;
      border-radius: $border-radius-medium;
      width: 200px;
    }

    .unable-to-scan {
      margin-left: 24px;
      p:first-child {
        font-weight: bold;
      }

      p:nth-child(2) {
        margin: 8px 0;
        color: $grey60;
      }

      button {
        padding: 0;
      }
    }
  }

  h3 {
    margin: 0;
  }

  .code-input {
    display: flex;
    flex-direction: column;
    margin-top: 8px;

    input {
      max-width: 400px;
    }

    small {
      margin-top: 4px;
    }
  }

  .actions {
    margin-top: 16px;

    button:first-child {
      margin-right: 16px;
    }

    .btn {
      height: 44px;

      // TODO: include in future general button refactoring
      &:disabled,
      &.disabled,
      &.off,
      &[disabled='disabled'] {
        background-color: $grey5;
        border-color: $grey5;
        color: $grey30;
        cursor: not-allowed;
        pointer-events: initial;
      }
    }

    .icon-btn {
      display: inline-flex;
    }
  }
}

.mfa-recovery-codes {
  ul {
    list-style: none;
    margin: 0;
    background-color: $grey5;
    border: 1px solid $grey15;
    border-radius: $border-radius-medium;
    padding: 24px;
  }

  li {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    p {
      margin: 0;
    }

    p:first-child {
      font-weight: bold;
      margin-right: 8px;
    }
  }
}

.info-box {
  background-color: #a6c8e7;
  border: 1px solid #22527e;
  padding: 24px;
  border-radius: $border-radius-medium;
  margin: 0 0 24px 0;

  & > div {
    display: flex;
    align-items: center;
  }

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    color: #22527e;
  }

  h3 {
    color: #22527e;
  }

  p {
    color: #22527e;
    margin: 8px 0 0 32px;
  }
}
</style>
