import statisticsService from '@/api/statisticsService/statisticsService';

import { defineStore } from 'pinia';

type State = {
  lifetime?: {
    commissionCurrencyId: number;
    earnings: number;
    sales: number;
  };
};

export const useStatisticsStore = defineStore('statistics', {
  state: (): State => ({
    lifetime: undefined,
  }),
  getters: {
    lifetimeSales: (state) =>
      state.lifetime ? state.lifetime.sales : undefined,
  },
  actions: {
    setLifetimeStatistics(data: State['lifetime']) {
      this.lifetime = data;
    },
    async fetchLifetimeStatistics() {
      const data = await statisticsService.getLifetimeStatistics();

      this.setLifetimeStatistics(data);
    },
  },
});
