import eventBus from '@/eventBus/eventBus';

import { defineStore } from 'pinia';

type Dialog = {
  id: string;
  component: object;
  data: object;
  modalInstance: {
    close: (args: unknown) => void;
    dismiss: (args: unknown) => void;
  };
  options: object;
};

type State = {
  currentDialogs: Dialog[];
};

export const useDialogsStore = defineStore('dialogs', {
  state: (): State => ({
    currentDialogs: [],
  }),
  getters: {
    dialogCount: (state): number => state.currentDialogs.length,
  },
  actions: {
    addDialog(dialog: Dialog) {
      this.currentDialogs.push(dialog);

      eventBus.$emit('dialogs:added');
    },
    removeDialog(id: string) {
      if (!this.currentDialogs?.length || !id) {
        return;
      }

      const dialog = this.currentDialogs.find((dialog) => dialog.id === id);

      if (!dialog) {
        return;
      }

      this.currentDialogs.splice(this.currentDialogs.indexOf(dialog), 1);

      eventBus.$emit('dialogs:removed');
    },
  },
});
