<template>
  <NoStuffPlaceholder
    :display="display"
    heading="POS.SHOP_SETTINGS.STARTPAGE.DESIGN_PREVIEW.EMPTY.HEADING"
    sub-heading="POS.SHOP_SETTINGS.STARTPAGE.DESIGN_PREVIEW.EMPTY.SUBHEADING"
    faq="POS.SHOP_SETTINGS.STARTPAGE.COLLECTION_PREVIEW.EMPTY.FAQ"
    :faq-link="faqLink"
    cta-icon="shirt"
    cta-text="POS.SHOP_SETTINGS.STARTPAGE.DESIGN_PREVIEW.EMPTY.LINK_UPLOAD"
    cta-target="partnerarea.ideas"
  />
</template>

<script>
import NoStuffPlaceholder from 'src/app/partnerarea/pos/shop/common/NoStuffPlaceholder.vue';
import smartFaqLinks from '@/smartFaq/smartFaqLinks';
import { StartPageData } from 'src/app/partnerarea/pos/shop/shopSavable/StartPageData';

export default {
  name: 'NoDesignPreviewPlaceholder',
  components: {
    NoStuffPlaceholder,
  },
  mixins: [StartPageData],
  data() {
    return {
      faqLink: smartFaqLinks.IDEA_CREATION_HELP,
    };
  },
  computed: {
    display() {
      const { designPreviewPossible } = this.startPage;
      return (
        typeof designPreviewPossible === 'boolean' && !designPreviewPossible
      );
    },
  },
};
</script>
