<template>
  <div class="ai-design-generator-prompt">
    <h2>{{ $t('AI_DESIGN_GENERATOR.PROMPT.HEADING') }}</h2>
    <p>{{ $t('AI_DESIGN_GENERATOR.PROMPT.SUBHEADING') }}</p>
    <textarea
      v-model="prompt"
      type="text"
      :placeholder="$t('AI_DESIGN_GENERATOR.PROMPT.PLACEHOLDER')"
      :disabled="!openUsage"
    />
    <Requirement
      class="promptRecommendation"
      :fullfilled="prompt.length > 120"
      text="AI_DESIGN_GENERATOR.PROMPT.RECOMMENDATION"
    />
    <div class="actions">
      <button
        class="sprd-btn-primary"
        :disabled="!openUsage"
        @click="submitPrompt"
      >
        {{ $t('AI_DESIGN_GENERATOR.PROMPT.GENERATE') }}
      </button>
      <AiDesignGeneratorLimit
        :usage-limit="usageLimit"
        :open-usage="openUsage"
        @buy-more="$emit('survey')"
      />
    </div>
    <div class="legal">
      <p v-html="$t('AI_DESIGN_GENERATOR.PROMPT.LEGAL')"></p>
      <SmartFaqLink :link="aiHelpLink.key" icon-only class="help-link" />
    </div>

    <div class="inspiration">
      <h3>{{ $t('AI_DESIGN_GENERATOR.PROMPT.EXAMPLES.HEADING') }}</h3>
      <p>
        {{ $t('AI_DESIGN_GENERATOR.PROMPT.EXAMPLES.SUBHEADING') }}
      </p>
      <ul>
        <li v-for="inspiration in inspirations">
          <img :src="inspiration.image" alt="AI Prompt Inspiration" />
          <p>{{ $t(inspiration.prompt) }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Requirement from 'src/app/components/requirement/Requirement.vue';
import AiDesignGeneratorLimit from './AiDesignGeneratorLimit.vue';
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';
import smartFaqLinks from '@/smartFaq/smartFaqLinks';

export default {
  name: 'AiDesignGeneratorPrompt',
  components: { AiDesignGeneratorLimit, Requirement, SmartFaqLink },
  props: {
    usageLimit: {
      type: Number,
      required: true,
    },
    openUsage: {
      type: Number,
      required: true,
    },
    prefill: {
      type: String,
    },
  },
  emits: ['onSubmit'],
  data() {
    return {
      prompt: '',
      inspirations: [
        {
          image: '/images/ai/inspiration1.png',
          prompt: 'AI_DESIGN_GENERATOR.EXAMPLE_PROMPT1',
        },
        {
          image: '/images/ai/inspiration2.png',
          prompt: 'AI_DESIGN_GENERATOR.EXAMPLE_PROMPT2',
        },
        {
          image: '/images/ai/inspiration3.png',
          prompt: 'AI_DESIGN_GENERATOR.EXAMPLE_PROMPT3',
        },
        {
          image: '/images/ai/inspiration4.png',
          prompt: 'AI_DESIGN_GENERATOR.EXAMPLE_PROMPT4',
        },
      ],
      aiHelpLink: smartFaqLinks.AI,
    };
  },
  created() {
    if (this.prefill) {
      this.prompt = this.prefill;
    }
  },
  methods: {
    submitPrompt() {
      if (this.prompt) {
        this.$emit('onSubmit', this.prompt);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.ai-design-generator-prompt {
  & > h2 {
    margin: 0 0 8px 0;
  }

  & > p {
    margin: 0 0 24px 0;
    color: $grey65;
    max-width: 70%;
  }
}

textarea {
  padding: 12px;
}

.help-link {
  margin-left: auto;
}

.promptRecommendation {
  margin: 16px 0;
}

.actions {
  display: flex;
  margin: 16px 0 0 0;
  align-items: center;

  p {
    margin: 0 0 0 16px;
  }
}

.legal {
  display: flex;
  margin: 12px 0 24px 0;

  p {
    @extend .text-sm;
    margin: 0;
    color: $grey65;
  }

  :deep(a) {
    text-decoration: underline;

    &:hover {
      color: $grey80;
    }
  }
}

button {
  padding: 12px 24px;
  margin: 0 16px 0 0;
}

.inspiration {
  padding: 24px 0 0 0;
  border-top: 1px solid $grey10;

  h3 {
    margin: 0 0 8px 0;
  }

  & > p {
    color: $grey65;
    margin: 0;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: grid;
    grid-template-columns: auto auto;

    li {
      display: flex;
      margin: 24px 0 0 0;

      img {
        width: 110px;
        height: auto;
      }

      p {
        margin: 0 24px 0 8px;
        @extend .text-sm;
        color: $grey65;
      }
    }
  }
}
</style>
