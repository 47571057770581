<template>
  <Banner
    v-if="linkHref && (hasShops || hasMarketplace)"
    class="banner"
    image="/images/money.svg"
    heading="DESIGNS.EARNINGS.HEADING"
    :link="linkText"
    @on-link-click="goToCommissionHelp"
  />
  <SmartFaqLink headless :link="relevantSmartFaqLinkKey" />
</template>

<script>
import Banner from './Banner.vue';
import smartFaqLinks from '@/smartFaq/smartFaqLinks';
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';
import { mapState } from 'pinia';
import { useSmartFaqStore } from '@/stores/smartFaq';

export default {
  name: 'EarningsBanner',
  components: {
    SmartFaqLink,
    Banner,
  },
  props: {
    hasShops: {
      type: Boolean,
    },
    hasMarketplace: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(useSmartFaqStore, ['relevantLinksByKey']),
    relevantSmartFaqLinkKey() {
      return this.hasShops
        ? smartFaqLinks.MDS_COMMISSION_HELP.key
        : smartFaqLinks.MP_COMMISSION_HELP.key;
    },
    linkText() {
      return this.hasShops
        ? 'DESIGNS.CONFIGURATION.COMMISSION.SHOP_EARNINGS'
        : 'DESIGNS.CONFIGURATION.COMMISSION.SPRD_EARNINGS';
    },
    linkHref() {
      return this.relevantLinksByKey(this.relevantSmartFaqLinkKey)?.href;
    },
  },
  methods: {
    goToCommissionHelp() {
      window.open(this.linkHref, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  margin: 24px 0;
}
</style>
