<template>
  <div class="taxation-eu-company">
    <div class="taxation-form-row">
      <p v-html="$t('ACCOUNT.TAXATION.EU_NO_EU.EXPLANATION1')"></p>
      <p>{{ $t('ACCOUNT.TAXATION.EU_NO_EU.EXPLANATION2') }}</p>
      <p>
        {{ $t('ACCOUNT.TAXATION.EU_NO_EU.UMSATZSTEUER_TOOLTIP_EXPLANATION') }}
      </p>
      <SmartFaqLink :link="taxationFAQLink" />
      <div class="tax-id" :class="{ 'has-error': validation.errors.taxId }">
        <LabelInput>
          <label for="tax-id">{{
            $t('ACCOUNT.TAXATION.EU_NO_EU.TAX_ID')
          }}</label>
          <div class="position-relative">
            <DebounceInput
              id="tax-id"
              type="text"
              :model-value="taxationData.taxId"
              :placeholder="obfuscatedData.taxId"
              @update:model-value="setTaxId"
            />
            <a
              v-if="!taxationData.taxId && obfuscatedData.taxId"
              href
              class="input-clear-button"
              @click.prevent="clearObfuscatedField({ field: 'taxId' })"
            >
              <Icon icon="close"></Icon>
            </a>
          </div>
          <AccountError
            v-if="validation.errors.taxId"
            :reason="validation.errors.taxId.reason"
          ></AccountError>
        </LabelInput>
      </div>

      <p
        class="taxid-separator"
        v-html="$t('ACCOUNT.TAXATION.EU_NO_EU.EXPLANATION3')"
      ></p>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import smartFaqLinks from '@/smartFaq/smartFaqLinks';

import AccountError from './../../errorMessage/AccountError.vue';
import LabelInput from '@/labelInput/LabelInput.vue';
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';
import DebounceInput from 'src/app/components/input/DebounceInput.vue';

export default {
  name: 'CompanyNonEU',
  components: {
    AccountError,
    LabelInput,
    SmartFaqLink,
    DebounceInput,
  },
  props: {
    validation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      taxationFAQLink: smartFaqLinks.WITHHOLDING_TAX_INFO,
    };
  },
  computed: {
    ...mapState({
      taxationData: (state) => state.userTaxation.data,
      obfuscatedData: (state) => state.userTaxation.obfuscatedData,
    }),
  },
  methods: {
    ...mapMutations({
      updateTaxId: 'userTaxation/setTaxId',
      clearObfuscatedField: 'userTaxation/clearObfuscatedField',
    }),
    setTaxId(taxId) {
      this.updateTaxId({ taxId });
    },
  },
};
</script>

<style lang="scss" scoped>
.taxation-eu-company {
  display: flex;

  .taxation-form-row {
    flex: 7;
    padding: 0 10% 0 0;
  }

  .info-box {
    flex: 3;
  }

  .link {
    position: absolute;
    right: 0;
    top: -20px;
  }

  .taxid-separator {
    margin: 16px 0 0 0;
  }

  .tax-id {
    display: flex;
    margin-top: 16px;

    .labelInput {
      width: 250px;
    }
  }
}
</style>
