import { createStore } from 'vuex';
import idea from './idea';
import ideas from './ideas';
import digitalProducts from './digitalProducts';
import digitalProduct from './digitalProduct';
import shop from './shop';
import account from './account';
import application from './application';
import user from './user';
import identity from './identity';
import settings from './settings';
import platform from './platform';
import notifications from './notifications';
import onboarding from './onboarding';
import dms from './dms';
import metrics from './metrics';
import toasts from './toasts';
import compositions from './compositions';
import composition from './composition';
import publishingCore from './publishingCore';
import liveChat from './liveChat';
import userNewsletter from './user/userNewsletter';
import userPaymentInfo from './user/userPaymentInfo';
import userTaxation from './user/userTaxation';
import publishing from './publishing/publishing';
import userTrolley from './user/userTrolley';

export default createStore({
  modules: {
    idea,
    ideas,
    digitalProducts,
    digitalProduct,
    compositions,
    composition,
    publishingCore,
    shop,
    account,
    application,
    user,
    identity,
    settings,
    platform,
    notifications,
    onboarding,
    dms,
    metrics,
    toasts,
    userNewsletter,
    userPaymentInfo,
    userTaxation,
    liveChat,
    publishing,
    userTrolley,
  },
});
