<template>
  <div class="idea-rejected">
    <div class="idea-rejected-hint">
      <p class="text-grey">
        {{ $t('DESIGNS.STATUS.REJECTED_HINT') }}
      </p>
    </div>

    <div class="status">
      <p>{{ $t('GENERAL.STATUS') }}:</p>
      <IdeaStatus :idea="idea" class="idea-rejected-status" />
    </div>
    <div class="reasons">
      <p>{{ $t('DESIGNS.CONFIGURATION.POINTS_OF_SALE.REJECTION_REASONS') }}:</p>
      <h3 v-for="rejectionReason in rejectionReasons" :key="rejectionReason">
        {{ $t(rejectionReasonName(rejectionReason)) }}
      </h3>
    </div>

    <SmartFaqLink
      :link="
        hasMarketplace
          ? smartFaqLinks.REJECTION_HELP
          : smartFaqLinks.SHOP_REJECTION_HELP
      "
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import IdeaStatus from 'src/app/partnerarea/idea/detail/IdeaStatus.vue';
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';
import smartFaqLinks from '@/smartFaq/smartFaqLinks';
import { rejectionReasonName } from '@/api/models/pointsOfSale/pointsOfSale';

export default {
  name: 'IdeaRejected',
  components: {
    IdeaStatus,
    SmartFaqLink,
  },
  data() {
    return {
      smartFaqLinks,
    };
  },
  computed: {
    ...mapState({
      idea: (s) => s.idea.current,
    }),
    ...mapGetters({
      hasMarketplace: 'idea/hasMarketplace',
    }),
    rejectionReasons() {
      return this.idea.legalStates[0].rejectionReason;
    },
  },
  methods: {
    rejectionReasonName,
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';
@import 'src/scss/styleguide/type';

.idea-rejected {
  background-color: $white;
  border-radius: $border-radius-medium;
  padding: 16px;

  & > div + div {
    margin-top: 24px;
  }
}

h3 {
  color: $pa-color-red;
  margin: 0 0 8px 0;
}

.idea-rejected-status {
  :deep(span) {
    color: $pa-color-red;
    @extend .heading3;
    margin: 0;
  }
}
</style>
