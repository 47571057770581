import './scss/main.scss';

import { createApp } from 'vue';
import router from './app/router/router';

import 'modernizer';
import 'smartbanner.js';
import 'chartist-plugin-tooltips-updated';
import * as Sentry from '@sentry/browser';

import customPlugins from './app/config/customPlugins';

import svg4everybody from 'svg4everybody';
import iconService from '@/icon/service/iconService';

import App from './App.vue';
import store from '@/store/Store';

import analytics from '@/tracking/analytics';
import { initUsertest } from '@/usertests/usertests';
import { createPinia } from 'pinia';

window.BUILD = {
  APPLICATION: __BUILD_APPLICATION,
  VERSION: __BUILD_VERSION,
  TIME: __BUILD_TIME,
};

svg4everybody({
  polyfill: true, // polyfill <use> elements for External Content
});

iconService.setOptions({
  spritePath: 'images/sprite.sprd.svg',
  version: SPRD.VERSION,
});

SPRD.ENVIRONMENT = SPRD.ENVIRONMENT.toUpperCase();
SPRD.PLATFORM = SPRD.PLATFORM.toUpperCase();

if (SPRD.ERROR_LOGGING_ACTIVE) {
  Sentry.init({
    dsn: 'https://c44a447370ec4957a78652f3c35b24ba@o284092.ingest.sentry.io/5341720',
    environment: SPRD.ENVIRONMENT,
    release: `partnerarea@${BUILD.VERSION}`,
    beforeBreadcrumb(crumb, hint) {
      if (crumb && crumb.data && crumb.data.url) {
        crumb.data.url = crumb.data.url.replace('apiKey=', 'AK=');
      }

      if (
        crumb.type === 'http' &&
        crumb.data.url.includes('google-analytics')
      ) {
        return null;
      } else {
        const traceId =
          crumb.type === 'http' &&
          hint &&
          hint.xhr &&
          hint.xhr.getAllResponseHeaders().includes('x-trace-id') &&
          hint.xhr.getResponseHeader('x-trace-id');

        if (traceId) {
          Sentry.addBreadcrumb({
            category: 'trace',
            message: `Trace Id: ${traceId}`,
            level: 'info',
          });
        }

        return crumb;
      }
    },
    denyUrls: [/adtm\.spreadshirts\.net/i, /adtm\.spreadshirts\.com/i],
    beforeSend(event) {
      if (window.sprdConsent?.getStatus) {
        const consent = window.sprdConsent.getStatus();

        if (
          consent?.functional &&
          event &&
          event.tags &&
          event.tags.vueErrorHandler
        ) {
          return event;
        }
      }

      return null;
    },
  });
  Sentry.configureScope(function (scope) {
    scope.setTag('platform', SPRD.PLATFORM);
  });
}

window.consentGiven = function () {
  analytics.init();
};

const pinia = createPinia();

let app = createApp(App);

initUsertest();

app.config.errorHandler = function (err, vm, info) {
  if (SPRD.ERROR_LOGGING_ACTIVE && err instanceof Error) {
    Sentry.withScope(function (scope) {
      scope.setTag('vueInfo', info);
      scope.setTag('vueErrorHandler', true);
      Sentry.captureException(err);
    });
  }

  throw err;
};

app.use(pinia);

app.use(store);

// if (!process || process.env.NODE_ENV !== 'test') {
app.use(router);
// }

app.use(customPlugins);

app.mount('#app');
