<template>
  <div
    v-if="currentRelevantLinks && currentRelevantLinks.length"
    class="smart-faq"
  >
    <h3>{{ $t('HELP_PANEL.FAQ.HEADING') }}</h3>
    <a
      v-for="link in currentRelevantLinks"
      :key="link.key"
      v-trackClickEvent="{
        name: 'help-panel-smart-faq-link-click',
        details: { articleId: link.articleId },
      }"
      :href="link.href"
      target="_blank"
      class="link-default-blue"
      :class="{ highlight: link.key === highlightLinkKey }"
    >
      {{ link.title }}
      <Icon icon="outside" />
    </a>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mapActions, mapState as mapPiniaState } from 'pinia';
import { useSmartFaqStore } from '@/stores/smartFaq';

export default {
  name: 'SmartFaq',
  computed: {
    ...mapPiniaState(useSmartFaqStore, [
      'currentRelevantLinks',
      'highlightLinkKey',
    ]),
    ...mapState({
      helpPanelExpanded: (s) => s.application.helpPanelExpanded,
    }),
  },
  watch: {
    helpPanelExpanded: function (newValue, oldValue) {
      if (!newValue && oldValue) {
        this.setHighlightLinkKey(null);
      }
    },
  },
  methods: {
    ...mapActions(useSmartFaqStore, ['setHighlightLinkKey']),
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/links';

@keyframes highlightLink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.smart-faq {
  h3 {
    margin: 0;
  }

  a {
    display: flex;
    justify-content: space-between;
    font-weight: normal;
    margin-top: 16px;

    .icon {
      margin-left: 12px;
      color: $pa-color-main;
      height: 16px;
      width: 16px;
      flex-shrink: 0;
    }

    &.highlight {
      @extend .link-main;
      animation: highlightLink 0.3s linear both;
      animation-delay: 0.3s;
    }

    &:hover {
      .icon {
        color: $pa-color-main-dark;
      }
    }
  }
}
</style>
