type Target = 'MP' | 'SHOP';

export type SmartFaqLink = {
  key: string;
  targets: {
    [key in Target]?: {
      articleId: string;
      isSection?: boolean;
      isCategory?: boolean;
    };
  };
};

const smartFaqLinks: Record<string, SmartFaqLink> = {
  PRINT_AREAS_PRICING: {
    key: 'PRINT_AREAS_PRICING',
    targets: {
      MP: {
        articleId: '360019566340',
      },
      SHOP: {
        articleId: '360011755760',
      },
    },
  },
  REJECTION: {
    key: 'REJECTION',
    targets: {
      MP: {
        articleId: '206779109',
      },
    },
  },
  IDEA_CREATION: {
    key: 'IDEA_CREATION',
    targets: {
      MP: {
        articleId: '206775649',
      },
    },
  },
  MP_COMMISSION_HELP: {
    key: 'MP_COMMISSION_HELP',
    targets: {
      MP: {
        articleId: '360011404220',
      },
    },
  },
  MDS_COMMISSION_HELP: {
    key: 'MDS_COMMISSION_HELP',
    targets: {
      SHOP: {
        articleId: '360011076040',
      },
    },
  },
  AFFILIATE_COMMISSION_HELP: {
    key: 'AFFILIATE_COMMISSION_HELP',
    targets: {
      SHOP: {
        articleId: '360011683879',
      },
    },
  },
  HELP_CENTER: {
    key: 'HELP_CENTER',
    targets: {
      MP: {
        articleId: '200829585',
        isCategory: true,
      },
    },
  },
  COMMISSION_INFO: {
    key: 'COMMISSION_INFO',
    targets: {
      MP: {
        articleId: '207905515',
      },
    },
  },
  VOLUME_COMMISSION_HELP: {
    key: 'VOLUME_COMMISSION_HELP',
    targets: {
      SHOP: {
        articleId: '360017571099',
      },
    },
  },
  DMS_STARTER: {
    key: 'DMS_STARTER',
    targets: {
      MP: {
        articleId: '360021467919',
      },
    },
  },
  DMS_PILOT: {
    key: 'DMS_PILOT',
    targets: {
      MP: {
        articleId: '360021467939',
      },
    },
  },
  DMS_ASTRONAUT: {
    key: 'DMS_ASTRONAUT',
    targets: {
      MP: {
        articleId: '360021443800',
      },
    },
  },
  DMS_SPACE_CAPTAIN: {
    key: 'DMS_SPACE_CAPTAIN',
    targets: {
      MP: {
        articleId: '360021443840',
      },
    },
  },
  DMS_MASTER_OF_THE_GALAXY: {
    key: 'DMS_MASTER_OF_THE_GALAXY',
    targets: {
      MP: {
        articleId: '360021468019',
      },
    },
  },
  DMS_LOST_IN_SPACE: {
    key: 'DMS_LOST_IN_SPACE',
    targets: {
      MP: {
        articleId: '360021468039',
      },
    },
  },
  DMS_PUBLISHING_LIMIT_REACHED: {
    key: 'DMS_PUBLISHING_LIMIT_REACHED',
    targets: {
      MP: {
        articleId: '360021691580',
      },
    },
  },
  TAXATION_INFO: {
    key: 'TAXATION_INFO',
    targets: {
      MP: {
        articleId: '207194399',
      },
    },
  },
  WITHHOLDING_TAX_INFO: {
    key: 'WITHHOLDING_TAX_INFO',
    targets: {
      MP: {
        articleId: '212640345',
      },
    },
  },
  REJECTION_HELP: {
    key: 'REJECTION_HELP',
    targets: {
      MP: {
        articleId: '206779109',
      },
    },
  },
  SHOP_REJECTION_HELP: {
    key: 'SHOP_REJECTION_HELP',
    targets: {
      SHOP: {
        articleId: '360010420380',
      },
    },
  },
  CUSTOMIZED_PRICING: {
    key: 'CUSTOMIZED_PRICING',
    targets: {
      MP: {
        articleId: '207153579',
      },
    },
  },
  PRINT_AREAS_PRICING_SHOP: {
    key: 'PRINT_AREAS_PRICING_SHOP',
    targets: {
      SHOP: {
        articleId: '360011076120',
      },
    },
  },
  GOOGLE_WEBMASTER: {
    key: 'GOOGLE_WEBMASTER',
    targets: {
      SHOP: {
        articleId: '360010528579',
      },
    },
  },
  GOOGLE_ANALYTICS_HELP: {
    key: 'GOOGLE_ANALYTICS_HELP',
    targets: {
      SHOP: {
        articleId: '360018137960',
      },
    },
  },
  SELL_WITH_GOOGLE: {
    key: 'SELL_WITH_GOOGLE',
    targets: {
      SHOP: {
        articleId: '4405231282066',
        isSection: true,
      },
    },
  },
  SELL_WITH_FACEBOOK: {
    key: 'SELL_WITH_FACEBOOK',
    targets: {
      SHOP: {
        articleId: '4404415444370',
        isSection: true,
      },
    },
  },
  YOUTUBE_MERCH_SHELF: {
    key: 'YOUTUBE_MERCH_SHELF',
    targets: {
      SHOP: {
        articleId: '360015978659',
      },
    },
  },
  YOUTUBE_MERCH_AVAILABILITY: {
    key: 'YOUTUBE_MERCH_AVAILABILITY',
    targets: {
      SHOP: {
        articleId: '360015073179',
      },
    },
  },
  IDEA_CREATION_HELP: {
    key: 'IDEA_CREATION_HELP',
    targets: {
      MP: {
        articleId: '360008958033',
      },
    },
  },
  SHOP_COLLECTION_HELP: {
    key: 'SHOP_COLLECTION_HELP',
    targets: {
      SHOP: {
        articleId: '360010529219',
      },
    },
  },
  SHOP_START_PAGE_HELP: {
    key: 'SHOP_START_PAGE_HELP',
    targets: {
      SHOP: {
        articleId: '360010528459',
      },
    },
  },
  PDV_INFO: {
    key: 'PDV_INFO',
    targets: {
      MP: {
        articleId: '360000983014',
      },
    },
  },
  POSTER_TEMPLATES: {
    key: 'POSTER_TEMPLATES',
    targets: {
      MP: {
        articleId: '360001698514',
      },
    },
  },
  INTPUB: {
    key: 'INTPUB',
    targets: {
      MP: {
        articleId: '360007362893',
      },
    },
  },
  JS_INTEGRATION: {
    key: 'JS_INTEGRATION',
    targets: {
      SHOP: {
        articleId: '360010529039',
      },
    },
  },
  PRICING_HELP: {
    key: 'PRICING_HELP',
    targets: {
      SHOP: {
        articleId: '360011203179',
      },
    },
  },
  PRODUCT_RATINGS: {
    key: 'PRODUCT_RATINGS',
    targets: {
      SHOP: {
        articleId: '360010528479',
      },
    },
  },
  PROMO_FAQ: {
    key: 'PROMO_FAQ',
    targets: {
      SHOP: {
        articleId: '360011203239',
      },
    },
  },
  LEGAL_TIPS: {
    key: 'LEGAL_TIPS',
    targets: {
      MP: {
        articleId: '206779259',
      },
    },
  },
  // TODO: replace with correct articleId
  TRACKING_METRICS: {
    key: 'TRACKING_METRICS',
    targets: {
      SHOP: {
        articleId: '360010528459',
      },
    },
  },
  TRACKING_FEW_METRICS: {
    key: 'TRACKING_FEW_METRICS',
    targets: {
      SHOP: {
        articleId: '5966886065820',
      },
    },
  },
  TIKTOK_TRACKING_PIXEL: {
    key: 'TIKTOK_TRACKING_PIXEL',
    targets: {
      SHOP: {
        articleId: '7665998491036',
      },
    },
  },
  MFA: {
    key: 'MFA',
    targets: {
      SHOP: {
        articleId: '11007247742364',
      },
      MP: {
        articleId: '11106099436188',
      },
    },
  },
  AI: {
    key: 'AI',
    targets: {
      SHOP: {
        articleId: '15169683949980',
      },
    },
  },
};

export default smartFaqLinks;
