<template>
  <div>
    <ShopProductFeed
      heading="POS.SHOP_SETTINGS.PRODUCT_FEED.GOOGLE_MERCHANT_CENTER_HEADING"
      subheading="POS.SHOP_SETTINGS.PRODUCT_FEED.GOOGLE_MERCHANT_CENTER_SUBHEADING"
      target-platform="google"
      :faq-link="faqLink"
    />
    <DomainVerification
      :model-value="settings.googleWebmasterId"
      regex="^[A-Za-z0-9_\-]*$"
      heading="SA.tracking.googleWebmasterId.label"
      subheading="SA.tracking.googleWebmasterId.description"
      label="SA.tracking.googleWebmasterId.label"
      :smart-faq-link="googleWebmasterLink"
      @on-change="settings.googleWebmasterId = $event"
    />

    <ShopTracking
      :toggle-value="tracking.googleAnalyticsEnabled"
      :tracking-id-value="tracking.googleAnalyticsTrackingId"
      toggle-label="SA.tracking.googleAnalyticsId.label"
      paragraph-text="POS.SHOP_SETTINGS.GOOGLE_MKT.TRACKING_PARAGRAPH"
      input-label="SA.tracking.userId.label"
      :smart-faq-link="googleAnalyticsLink"
      regex="^UA-[0-9]+-[0-9]+$|^G-[0-9A-Z]+$"
      @toggle-input="tracking.googleAnalyticsEnabled = $event"
      @tracking-id-input="tracking.googleAnalyticsTrackingId = $event"
    />
  </div>
</template>

<script>
import { TrackingData } from 'src/app/partnerarea/pos/shop/shopSavable/TrackingData';
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import smartFaqLinks from '@/smartFaq/smartFaqLinks';
import DomainVerification from './DomainVerification.vue';
import ShopProductFeed from './ShopProductFeed.vue';
import ShopTracking from './ShopTracking.vue';

export default {
  name: 'GoogleMarketing',
  components: {
    DomainVerification,
    ShopProductFeed,
    ShopTracking,
  },
  mixins: [TrackingData, SettingsData],
  data() {
    return {
      googleWebmasterLink: smartFaqLinks.GOOGLE_WEBMASTER,
      googleAnalyticsLink: smartFaqLinks.GOOGLE_ANALYTICS_HELP,
      faqLink: smartFaqLinks.SELL_WITH_GOOGLE,
    };
  },
  created() {
    this.$store.commit('shop/setLivePreview', { path: '' });
  },
};
</script>
