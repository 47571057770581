<template>
  <EmptyTimeline :heading="heading" :subheading="subheading">
    <template #links>
      <SmartFaqLink :link="link" :title="linkTitle" />
    </template>
  </EmptyTimeline>
</template>

<script>
import EmptyTimeline from 'src/app/components/charts/timeline/EmptyTimeline.vue';
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';
import smartFaqLinks from '@/smartFaq/smartFaqLinks';

export default {
  name: 'EmptyMetrics',
  components: {
    EmptyTimeline,
    SmartFaqLink,
  },
  data() {
    return {
      heading: 'STATISTICS.TRACKING_METRICS.EMPTY_METRICS_CHART.HEADING',
      subheading: 'STATISTICS.TRACKING_METRICS.EMPTY_METRICS_CHART.SUB_HEADING',
      linkTitle: 'STATISTICS.TRACKING_METRICS.EMPTY_METRICS_CHART.HELP_LINK',
      link: smartFaqLinks.TRACKING_FEW_METRICS,
    };
  },
};
</script>
