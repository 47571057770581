<template>
  <div
    class="youtubePageContainer"
    :class="{
      youtubePageContainerUnsupported:
        youtubeSelectedChannelSellables &&
        !youtubeSelectedChannelSellables.loading &&
        youtubeSelectedChannelSellables.unsupportedDomain,
    }"
  >
    <div v-if="youtubeSelectedChannelSellables">
      <LoadingHeart
        v-if="youtubeSelectedChannelSellables.loading"
        class="loaderWithOffset"
      />
      <NoStuffPlaceholder
        :display="
          !youtubeSelectedChannelSellables.loading &&
          youtubeSelectedChannelSellables.unsupportedDomain
        "
        heading="POS.SHOP_SETTINGS.YOUTUBE_MERCH_SHELF.UNSUPPORTED_WARNING.HEADING"
        sub-heading="POS.SHOP_SETTINGS.YOUTUBE_MERCH_SHELF.UNSUPPORTED_WARNING.SUBHEADING"
        faq="POS.SHOP_SETTINGS.STARTPAGE.COLLECTION_PREVIEW.EMPTY.FAQ"
        :faq-link="faqLink"
      />
      <div
        v-if="
          !youtubeSelectedChannelSellables.loading &&
          !youtubeSelectedChannelSellables.unsupportedDomain
        "
      >
        <YoutubeSellableTiles
          :sellables="youtubeSelectedChannelSellables.pendingProducts"
          :tile-type="statePending"
        />
        <YoutubeSellableTiles
          :sellables="youtubeSelectedChannelSellables.toBeRemovedProducts"
          :tile-type="stateRemoved"
        />
        <YoutubeSellableTiles
          :sellables="youtubeSelectedChannelSellables.syncedProducts"
          :tile-type="stateSynced"
        />
      </div>
    </div>
    <div v-else class="selectChannelPlaceholder">
      {{ $t('POS.SHOP_SETTINGS.YOUTUBE_MERCH_SHELF.SELECT_CHANNEL') }}
    </div>
  </div>
</template>

<script>
import LoadingHeart from 'src/app/commons/LoadingHeart/LoadingHeart.vue';
import NoStuffPlaceholder from 'src/app/partnerarea/pos/shop/common/NoStuffPlaceholder.vue';
import smartFaqLinks from '@/smartFaq/smartFaqLinks';
import YoutubeSellableTiles from './YoutubeSellableTiles.vue';
import {
  STATE_PENDING,
  STATE_SYNCED,
  STATE_TO_BE_REMOVED,
} from './youtubeSellableStates';

export default {
  name: 'YoutubePage',
  components: { LoadingHeart, NoStuffPlaceholder, YoutubeSellableTiles },
  data() {
    return {
      statePending: STATE_PENDING,
      stateSynced: STATE_SYNCED,
      stateRemoved: STATE_TO_BE_REMOVED,
    };
  },
  computed: {
    youtubeSelectedChannelSellables() {
      return this.$store.state.shop.youtubeSellables[
        this.$store.state.shop.youtubeSelectedChannelId
      ];
    },
    faqLink() {
      return smartFaqLinks.YOUTUBE_MERCH_AVAILABILITY;
    },
  },
  async created() {
    this.$store.commit('shop/setLivePreview', { path: '', visible: false });
  },
};
</script>

<style lang="scss">
@import 'src/scss/styleguide/colors';
// this hack ensures that padding and loading state background are grey instead of white
// (which they usually are for shop area pages)
#application.state-shop-area-youtubeMerchShelf {
  .context {
    .settings {
      background-color: $sprd-color-lightest-grey;
    }
  }
}
</style>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
.selectChannelPlaceholder {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  font-size: 24px;
  background-color: inherit;
  font-weight: bold;
}

.youtubePageContainer {
  background-color: $sprd-color-lightest-grey;
  padding-bottom: 48px;
  max-width: 1400px;
  margin: 0 auto;
  overflow: hidden;
}

.youtubePageContainerUnsupported {
  height: 100% !important;
}

.loaderWithOffset {
  margin-top: calc(50vh - 100px);
}
</style>
