<template>
  <NoStuffPlaceholder
    :display="display"
    heading="POS.SHOP_SETTINGS.LISTPAGE.EMPTY.HEADING"
    sub-heading="POS.SHOP_SETTINGS.LISTPAGE.EMPTY.SUBHEADING"
    faq="POS.SHOP_SETTINGS.STARTPAGE.COLLECTION_PREVIEW.EMPTY.FAQ"
    :faq-link="faqLink"
    cta-icon="shirt"
    cta-text="POS.SHOP_SETTINGS.STARTPAGE.PRODUCT_PREVIEW.EMPTY.LINK_UPLOAD"
    cta-target="partnerarea.ideas"
  />
</template>

<script>
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import NoStuffPlaceholder from './NoStuffPlaceholder.vue';
import smartFaqLinks from '@/smartFaq/smartFaqLinks';

export default {
  name: 'NoProductsPlaceholder',
  components: {
    NoStuffPlaceholder,
  },
  mixins: [SettingsData],
  data() {
    return {
      faqLink: smartFaqLinks.IDEA_CREATION_HELP,
    };
  },
  computed: {
    display() {
      // this flag actually means there is nothing published to the shop yet
      return this.config.shopCurrencyEditable === true;
    },
  },
};
</script>
