import { genId } from 'src/app/commons/utils';
import { useDialogsStore } from '../../stores/dialogs';

// its okay if dialogs have no rejection handling
window.addEventListener('unhandledrejection', function (promiseRejectionEvent) {
  if (promiseRejectionEvent?.reason === 'dialogDismiss') {
    promiseRejectionEvent.preventDefault();
  }
});

document.addEventListener('keydown', function (evt) {
  const dialogsStore = useDialogsStore();

  if (evt.key === 'Escape' && dialogsStore.dialogCount > 0) {
    const latestDialog =
      dialogsStore.currentDialogs[dialogsStore.currentDialogs.length - 1];
    if (!latestDialog?.options?.preventEsc) {
      latestDialog.modalInstance.dismiss();
    }
  }
});

export default {
  async openDialog(component, data, options = {}) {
    const dialogsStore = useDialogsStore();
    const dialogId = genId('dialog');

    return new Promise((resolve, reject) => {
      dialogsStore.addDialog({
        id: dialogId,
        component,
        data,
        options,
        modalInstance: {
          close: (args) => {
            dialogsStore.removeDialog(dialogId);
            resolve(args);
          },
          dismiss: (args) => {
            dialogsStore.removeDialog(dialogId);

            if (args && !(args instanceof Event)) {
              reject(args);
            } else {
              reject('dialogDismiss');
            }
          },
        },
      });
    });
  },
  dismissAllDialogs() {
    const dialogsStore = useDialogsStore();

    dialogsStore.currentDialogs.forEach((dialog) =>
      dialog.modalInstance.dismiss(),
    );
  },
};
