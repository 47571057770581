<template>
  <div>
    <h2>{{ name }}</h2>
    <div class="idea-summary">
      <div class="left">
        <div class="design">
          <IdeaPreviewImage
            class="preview-image"
            :idea="idea"
            :checked-background="true"
            :width="250"
            :height="250"
          />
          <div class="actions">
            <a
              v-if="downloadAvailable"
              download
              target="_self"
              :href="designDownloadLink"
              class="action link icon-link download"
            >
              <Icon icon="savedisk" />
              {{ $t('GENERAL.DOWNLOAD') }}
            </a>
            <a
              href="#"
              class="action link icon-link delete"
              @click.prevent="deleteIdea"
            >
              <Icon icon="bin" />
              {{ $t('GENERAL.DELETE') }}
            </a>
            <VDropdown class="action">
              <a href="#" class="link icon-link info" @click.prevent>
                <Icon icon="info-circle" />
                {{ $t('DASHBOARD.LEARN_MORE') }}
              </a>
              <template #popper>
                <IdeaInfos :idea="idea" />
              </template>
            </VDropdown>
          </div>
        </div>
      </div>
      <div v-if="!isFullyRejected" class="right">
        <div class="section pos" :class="{ attention: attentionPos }" data-pos>
          <div class="heading">
            <h4>
              <Icon icon="error" />
              {{ $t('DESIGNS.POINTS_OF_SALE') }}
            </h4>
            <a
              v-trackClickEvent="{ name: 'idea-detail-edit-pos' }"
              href="#"
              class="link-main icon-link edit-pos"
              @click.prevent="$emit('editPos')"
            >
              <Icon icon="pen" />
              {{ $t('GENERAL.EDIT') }}
            </a>
          </div>
          <div v-if="!attentionPos" class="section-content">
            <ul class="pos-list">
              <li
                v-if="hasMarketplace"
                :class="`idea-state-${marketplace.status.toLowerCase()}`"
              >
                <MarketplaceLogo class="logo" />
                <small>{{ $t('GENERAL.MARKETPLACE') }}</small>
                <div class="idea-pos-status">
                  <p v-if="marketplace.status === 'ONLINE'">
                    <a
                      href="#"
                      class="link-main"
                      @click.prevent="openMpPublishingDetails"
                      >{{ $t('GENERAL.PREVIEW') }}</a
                    >
                  </p>
                  <p v-if="marketplace.status === 'PENDING'">
                    {{ $t('DESIGNS.STATUS.REVIEW') }}
                  </p>
                  <p v-if="marketplace.status === 'REJECTED'">
                    {{ $t('DESIGNS.STATUS.REJECTED') }}
                    <VDropdown :triggers="['click']" class="hint-popover">
                      <a href="#" class="link-blue info-link" @click.prevent>
                        <Icon
                          class="icon"
                          icon="info-circle"
                          style="width: 20px; height: 20px"
                        />
                      </a>
                      <template #popper>
                        <p>{{ getRejectionTooltip(marketplace) }}</p>
                        <SmartFaqLink
                          :link="smartFaqLinks.REJECTION_HELP"
                          class="help-link"
                        />
                      </template>
                    </VDropdown>
                  </p>
                </div>
              </li>
              <li
                v-for="shop in shops"
                :key="shop.id"
                :class="`idea-state-${shop.status.toLowerCase()}`"
              >
                <SpreadshopLogo class="logo" />
                <small class="block text-truncate">{{
                  shop.pointOfSale.name
                }}</small>
                <div class="idea-pos-status">
                  <p v-if="shop.status === 'ONLINE'">
                    <a
                      href="#"
                      class="link-main"
                      @click.prevent="
                        openShopPublishingDetails(shop.pointOfSale.target.id)
                      "
                      >{{ $t('GENERAL.PREVIEW') }}</a
                    >
                  </p>

                  <p v-if="shop.status === 'PENDING'">
                    {{ $t('DESIGNS.STATUS.REVIEW') }}
                  </p>
                  <p v-if="shop.status === 'REJECTED'">
                    {{ $t('DESIGNS.STATUS.REJECTED') }}
                    <VDropdown :triggers="['click']" class="hint-popover">
                      <a href="#" class="link-blue info-link" @click.prevent>
                        <Icon
                          class="icon"
                          icon="info-circle"
                          style="width: 20px; height: 20px"
                        />
                      </a>
                      <template #popper>
                        <p>{{ getRejectionTooltip(shop) }}</p>
                        <SmartFaqLink
                          :href="smartFaqLinks.SHOP_REJECTION_HELP"
                          class="help-link"
                        />
                      </template>
                    </VDropdown>
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div v-else class="empty-cta">
            <p>{{ $t('DESIGNS.PUBLISHING.NOT_PUBLISHED') }}</p>
            <button
              v-trackClickEvent="{ name: 'idea-detail-edit-pos' }"
              class="btn btn-primary icon-btn"
              type="button"
              @click="$emit('editPos')"
            >
              <Icon icon="rocket" />
              {{ $t('DESIGNS.DETAILS.PUBLISH_UNPUBLISH') }}
            </button>
          </div>
        </div>
        <div
          class="section assortment"
          :class="{ attention: attentionAssortment }"
          data-assortment
        >
          <div class="heading">
            <h4>
              <Icon icon="error" />
              {{ $t('DESIGNS.DETAILS.ASSORTMENT_SUMMARY') }}
              <p>
                {{ sellableCount }}
                {{ $t('STATISTICS.BESTSELLERS.TOP_PRODUCT_TYPES') }}
              </p>
            </h4>
            <a
              id="edit-assortment"
              v-trackClickEvent="{ name: 'idea-detail-edit-assortment' }"
              href="#"
              class="link-main icon-link"
              @click.prevent="$emit('editAssortment')"
            >
              <Icon icon="pen" />
              {{ $t('GENERAL.EDIT') }}
            </a>
          </div>
          <div v-if="!attentionAssortment" class="section-content">
            <ul class="assortment-list">
              <li
                v-for="group in assortmentSummary"
                :key="group.name"
                class="text-truncate"
              >
                {{ group.name }} - {{ group.count }}
              </li>
            </ul>
          </div>
          <div v-else class="empty-cta">
            <p>
              {{ $t('DESIGNS.CONFIGURATION.ADD_PRODUCTS_SUBHEADING') }}
            </p>
            <button
              v-trackClickEvent="{ name: 'idea-detail-edit-assortment' }"
              class="btn btn-primary icon-btn"
              type="button"
              @click="$emit('editAssortment')"
            >
              <Icon icon="star-product" />
              {{ $t('DESIGNS.CONFIGURATION.ADD_PRODUCTS_BUTTON') }}
            </button>
          </div>
        </div>
        <div class="section" data-name>
          <div class="heading">
            <h4>
              <Icon icon="attention" />
              {{ $t('DESIGNS.META_DATA') }}
            </h4>
            <a
              id="edit-meta-data"
              v-trackClickEvent="{ name: 'idea-detail-edit-meta-data' }"
              href="#"
              class="link-main icon-link"
              @click.prevent="$emit('editMetaData')"
            >
              <Icon icon="pen" />
              {{ $t('GENERAL.EDIT') }}
            </a>
          </div>
          <div class="section-content">
            <p v-if="description">{{ description }}</p>
            <ul class="tag-list">
              <li v-for="tag in tags" :key="tag">{{ tag }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div v-else class="right"><IdeaRejected class="idea-rejected" /></div>
    </div>
    <div class="banner">
      <GraphicsServiceBanner v-if="isFullyRejected" />
      <EarningsBanner
        v-if="!isFullyRejected"
        :has-shops="hasShops"
        :has-marketplace="hasMarketplace"
      />
    </div>
    <IdeaStatistics class="idea-stats" :idea="idea" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import assortmentHelper from '@/assortmentHelper/assortmentHelper';
import IdeaInfos from './ideaInfos/IdeaInfos.vue';
import IdeaPreviewImage from 'src/app/partnerarea/idea/previewImage/IdeaPreviewImage.vue';
import MarketplaceLogo from '@/logos/MarketplaceLogo.vue';
import SpreadshopLogo from '@/logos/SpreadshopLogo.vue';
import IdeaStatistics from './statistics/IdeaStatistics.vue';
import IdeaRejected from 'src/app/partnerarea/idea/detail/IdeaRejected.vue';
import GraphicsServiceBanner from 'src/app/components/banners/GraphicsServiceBanner.vue';
import EarningsBanner from 'src/app/components/banners/EarningsBanner.vue';
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';
import smartFaqLinks from '@/smartFaq/smartFaqLinks';
import IdeaDeletionDialog from 'src/app/partnerarea/idea/delete/IdeaDeleteDialog.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import MpInternationalPublishingStatusDialog from 'src/app/partnerarea/idea/detail/publishingStatusDetails/MpInternationalPublishingStatusDialog.vue';
import ShopInternationalPublishingStatusDialog from 'src/app/partnerarea/idea/detail/publishingStatusDetails/ShopInternationalPublishingStatusDialog.vue';
import ideaHelper from '@/ideaHelper/ideaHelper';
import { rejectionReasonName } from '@/api/models/pointsOfSale/pointsOfSale';

export default {
  name: 'IdeaSummary',
  components: {
    IdeaInfos,
    IdeaPreviewImage,
    MarketplaceLogo,
    SpreadshopLogo,
    IdeaStatistics,
    SmartFaqLink,
    IdeaRejected,
    GraphicsServiceBanner,
    EarningsBanner,
  },
  emits: ['editMetaData', 'editAssortment', 'editPos'],
  data() {
    return {
      assortmentSummary: [],
      smartFaqLinks,
    };
  },
  computed: {
    ...mapState({
      idea: (s) => s.idea.current,
    }),
    ...mapGetters('idea', {
      name: 'name',
      description: 'description',
      tags: 'tags',
      hasShops: 'hasShops',
      hasMarketplace: 'hasMarketplace',
      marketplace: 'selectedMarketplace',
      shops: 'selectedShops',
      getFirstRejectionReason: 'firstRejectionReason',
      multipleDesigns: 'multipleDesigns',
      designs: 'designs',
      designDownloadLink: 'designDownloadLink',
    }),
    downloadAvailable() {
      return !this.multipleDesigns && !ideaHelper.isEmbroideryIdea(this.idea);
    },
    attentionPos() {
      return !this.hasMarketplace && !this.hasShops;
    },
    attentionAssortment() {
      return !this.sellableCount;
    },
    isFullyRejected() {
      return !this.idea.legalStates.some((ls) => ls.publishingAllowed);
    },
    sellableCount() {
      let count;

      if (this.idea?.assortment) {
        count = assortmentHelper.getSelectedSellables(
          this.idea.assortment,
        ).length;
      }

      return count;
    },
  },
  async created() {
    this.assortmentSummary = assortmentHelper.getSubGroupSummary(
      this.idea.assortment,
    );
  },
  methods: {
    rejectionReasonName,
    getRejectionTooltip(pos) {
      return `${this.$t(
        'DESIGNS.CONFIGURATION.POINTS_OF_SALE.REJECTION_REASONS',
      )}: ${this.$t(
        rejectionReasonName(
          this.getFirstRejectionReason(pos.pointOfSale.type) ||
            (pos?.rejectionReason.length && pos.rejectionReason[0]),
        ),
      )}`;
    },
    deleteIdea() {
      dialogService.openDialog(IdeaDeletionDialog, [this.idea]).then(() => {
        this.$router.push({ name: 'partnerarea.ideas' });
      });
    },
    openMpPublishingDetails() {
      dialogService.openDialog(MpInternationalPublishingStatusDialog);
    },
    openShopPublishingDetails(shopId) {
      dialogService.openDialog(ShopInternationalPublishingStatusDialog, {
        shopId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';
@import 'src/scss/styleguide/type';

h2 {
  margin: 12px 0 24px 0;
}

.idea-summary {
  display: flex;
}

.left {
  margin-right: 24px;
  flex-shrink: 0;
  width: 265px;
}

.design {
  background-color: $white;
  border-radius: $border-radius-medium;
  overflow: hidden;

  .preview-image:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.right {
  width: 100%;
}

.idea-rejected {
  height: 100%;
}

.banner {
  margin-bottom: 0px;
}

.idea-stats {
  margin-top: 24px;
}

.actions {
  display: flex;
  padding: 16px 16px 8px 16px;
  border-top: 2px solid $grey5;
  width: 100%;
  justify-content: space-between;

  .action {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 0;

    :deep(.trigger) {
      display: block !important;
    }
  }

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 8px;
    text-align: center;
    @extend .text-xs;

    .icon {
      margin: 0 0 8px 0;
    }

    &.download {
      .icon,
      &:hover,
      &:focus {
        color: $pa-color-green;
      }
    }

    &.delete {
      .icon,
      &:hover,
      &:focus {
        color: $pa-color-red;
      }
    }

    &.info {
      .icon,
      &:hover,
      &:focus {
        color: $pa-color-main;
      }
    }
  }
}

.section {
  width: 100%;
  background-color: $white;
  border-radius: $border-radius-medium;
  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  & > div {
    padding: 16px 24px;
  }

  &.pos,
  &.assortment {
    .section-content {
      padding-bottom: 24px + 8px;
    }
  }

  .heading {
    border-bottom: 2px solid $grey5;
    display: flex;
    justify-content: space-between;

    h4 {
      margin: 0;
      display: flex;
      align-items: center;

      .icon {
        display: none;
        margin-right: 16px;
        height: 24px;
        width: 24px;
      }

      p {
        font-weight: normal;
        margin: 0 0 0 8px;
      }
    }

    a {
      margin-left: 8px;
    }
  }

  h3 {
    margin: 0;
  }

  .pos-list,
  .assortment-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -16px;

    li {
      margin: 0 8px 8px 0;
      width: 200px;
      position: relative;
    }

    p {
      margin: 0;
      font-weight: bold;
    }
  }

  .pos-list {
    li {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border: 1px solid $grey20;
      border-radius: $border-radius-medium;
      padding: 12px;

      .logo {
        height: 16px;
        margin-bottom: 8px;
      }

      small {
        color: $grey60;
        margin-bottom: 16px;
        max-width: 100%;
      }
    }
  }

  .empty-cta {
    p {
      color: $grey80;
      margin: 0 0 8px 0;
    }

    .btn {
      height: 32px;

      .icon {
        height: 16px;
        width: 16px;
      }
    }
  }

  &.assortment {
    h3 {
      margin-bottom: 8px;
    }
  }

  .edit-pos {
    position: relative;
  }

  .idea-pos-status p {
    display: flex;
    align-items: center;
    line-height: 22px;

    .hint-popover {
      display: flex;
    }

    .info-link {
      display: flex;
      align-items: center;
      margin-left: 8px;

      .icon {
        width: 22px;
        height: 22px;
      }
    }
  }

  &.attention {
    .heading {
      h4 {
        color: $pa-color-red;
      }

      .icon {
        display: inline-block;
      }
    }
  }
}
</style>
