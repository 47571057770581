<template>
  <div>
    <SmartFaqLink class="faqLine" :link="faqLink" />
    <div v-if="displayChannelSelection">
      <Dropdown
        class="channelSelect"
        :label="$t('POS.SHOP_SETTINGS.YOUTUBE_MERCH_SHELF.SELECT_CHANNEL')"
        :items="channels"
        :item-key="(channel) => channel.channelId"
        :item-to-string="(channel) => channel.name"
        @change="updateChannel"
      />
    </div>
    <div v-if="selectedChannel && !selectedChannel.loading">
      <a
        :href="channelLink"
        class="channelLink link link-main icon-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon icon="outside" />
        {{ selectedChannelId }}
      </a>
      <h4 class="statesHeading">
        {{ $t('POS.SHOP_SETTINGS.YOUTUBE_MERCH_SHELF.STATES_HEADING') }}
      </h4>
      <YoutubeState :channel-id="selectedChannelId" :state="statePending" />
      <YoutubeState :channel-id="selectedChannelId" :state="stateRemoved" />
      <YoutubeState :channel-id="selectedChannelId" :state="stateSynced" />
    </div>
  </div>
</template>

<script>
import YoutubeState from './YoutubeState.vue';
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';
import smartFaqLinks from '@/smartFaq/smartFaqLinks';
import {
  STATE_PENDING,
  STATE_SYNCED,
  STATE_TO_BE_REMOVED,
} from './youtubeSellableStates';
import { shopAPI } from '@/api/shop/shopAPI';
import Dropdown from 'src/app/commons/Dropdown/Dropdown.vue';

export default {
  name: 'YoutubeNav',
  components: {
    YoutubeState,
    SmartFaqLink,
    Dropdown,
  },
  data() {
    return {
      faqLink: smartFaqLinks.YOUTUBE_MERCH_SHELF,
      statePending: STATE_PENDING,
      stateSynced: STATE_SYNCED,
      stateRemoved: STATE_TO_BE_REMOVED,
    };
  },
  computed: {
    channels() {
      return this.$store.state.shop.youtubeChannels;
    },
    selectedChannelId() {
      return this.$store.state.shop.youtubeSelectedChannelId;
    },
    selectedChannel() {
      if (!this.selectedChannelId) {
        return null;
      }

      return this.$store.state.shop.youtubeSellables[this.selectedChannelId];
    },
    displayChannelSelection() {
      return (
        this.$store.state.shop.youtubeChannels &&
        this.$store.state.shop.youtubeChannels.length >= 2
      );
    },
    channelLink() {
      if (this.selectedChannelId == null) {
        return null;
      }

      return this.channels.find(
        (channel) => channel.channelId === this.selectedChannelId,
      ).channelUrl;
    },
  },
  async created() {
    await this.$store.dispatch('shop/setYoutubeSelectedChannelId', null);
    this.$store.commit('shop/setYoutubeChannels', null);

    const result = await shopAPI.getYoutubeChannels(this.$store);
    this.$store.commit('shop/setYoutubeChannels', result);

    if (result.length === 1) {
      await this.$store.dispatch(
        'shop/setYoutubeSelectedChannelId',
        result[0].channelId,
      );
    }
  },
  methods: {
    updateChannel(channel) {
      this.$store.dispatch(
        'shop/setYoutubeSelectedChannelId',
        channel.channelId,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
.channelLink {
  margin-top: 16px;
}
.channelSelect {
  margin-top: 16px;
}
.faqLine {
  margin-top: -6px;
}
.statesHeading {
  margin-top: 26px;
  margin-bottom: 16px;
}
</style>
