import { defineStore } from 'pinia';

import localeService from 'src/app/commons/localeService/localeService';
import apiClient from '@/api/client/apiClient';
import {
  SmartFaqTarget,
  SmartFaqTargetData,
} from '@/smartFaq/smartFaqTypes.ts';
import smartFaqLinks, { SmartFaqLink } from '@/smartFaq/smartFaqLinks.ts';
import store from '@/store/Store';

type LinkData = {
  key: string;
  targets: {
    [key in SmartFaqTarget]?: SmartFaqTargetData;
  };
};

type State = {
  currentLinks: LinkData[];
  linkData: Record<string, LinkData>;
  smartFaqLinks: Record<string, SmartFaqLink>;
  highlightLinkKey?: string;
};

type RelevantLink = Partial<SmartFaqTargetData & { key: string }>;

const getHelpLinks = (
  articleId: string,
  {
    isCategory,
    spreadshop,
    isSection,
  }: { isCategory?: boolean; spreadshop?: boolean; isSection?: boolean },
) => {
  const locale = localeService.getLocale();
  let language = localeService.getLanguage();

  if (['en_US', 'en_GB', 'fr_CA'].includes(locale)) {
    language = locale.replace('_', '-').toLowerCase();
  } else if (locale === 'en_CA') {
    language = 'en-us';
  }

  const domain = spreadshop ? 'spreadshop.support' : 'spreadshirt.com';
  let type = 'articles';
  if (isCategory) {
    type = 'categories';
  }
  if (isSection) {
    type = 'sections';
  }
  return {
    href: `https://help.${domain}/hc/${language}/${type}/${articleId}`,
    apiUrl: `https://help.${domain}/api/v2/help_center/${language}/${type}/${articleId}`,
  };
};

export const useSmartFaqStore = defineStore('smartFaq', {
  state: (): State => ({
    currentLinks: [],
    linkData: {},
    smartFaqLinks,
    highlightLinkKey: undefined,
  }),

  getters: {
    uniqueCurrentLinks(state): LinkData[] {
      return [...new Set([...state.currentLinks])];
    },
    currentRelevantLinks(): (RelevantLink | null)[] {
      return this.uniqueCurrentLinks
        .map(this.relevantLink)
        .filter((link) => link);
    },
    relevantLinksByKey(state): (linkKey: string) => RelevantLink | null {
      return (linkKey) => this.relevantLink(state.linkData[linkKey]);
    },
    relevantLink(): (link: LinkData) => RelevantLink | null {
      return (link) => {
        if (!link?.targets.SHOP && !link?.targets.MP) {
          return null;
        }

        const partnerType = store.getters['user/partnerType'];
        let relevantPart;
        if (partnerType === 'SHOP') {
          relevantPart = link.targets.SHOP || link.targets.MP;
        } else {
          relevantPart = link.targets.MP || link.targets.SHOP;
        }

        return {
          key: link.key,
          ...relevantPart,
        };
      };
    },
  },

  actions: {
    removeLink(linkKey: string) {
      // only remove link once even if it could be included multiple times
      const index = this.currentLinks.findIndex((link) => link.key === linkKey);
      if (index >= 0) {
        this.currentLinks.splice(index, 1);
      }
    },
    setLinkData(linkData: LinkData) {
      this.linkData[linkData.key] = linkData;
    },
    setHighlightLinkKey(value: string) {
      this.highlightLinkKey = value;
    },
    async addLink(linkKey: string) {
      if (!this.linkData[linkKey]) {
        return;
      }

      await this.fetchLinkMetaData(linkKey);

      this.currentLinks.push(this.linkData[linkKey]);

      return this.relevantLink(this.linkData[linkKey]);
    },
    async init() {
      Object.values(smartFaqLinks).forEach((link) => this.addLinkConfig(link));
    },
    async addLinkConfig(link: LinkData) {
      if (this.linkData[link.key]) {
        return;
      }

      const linkData: LinkData = {
        key: link.key,
        targets: {},
      };

      Object.entries(link.targets).forEach(([target, targetConfig]) => {
        linkData.targets[target as SmartFaqTarget] = {
          ...targetConfig,
          ...getHelpLinks(targetConfig.articleId, {
            spreadshop: target === 'SHOP',
            isCategory: targetConfig?.isCategory,
            isSection: targetConfig?.isSection,
          }),
        };
      });

      this.setLinkData(linkData);
    },
    async fetchLinkMetaData(linkKey: string) {
      if (
        this.linkData[linkKey].targets.MP?.title ||
        this.linkData[linkKey].targets.SHOP?.title
      ) {
        return;
      }

      const linkData = this.linkData[linkKey];

      await Promise.all(
        Object.values(linkData.targets).map(async (targetConfig) => {
          const meta = await apiClient.request({
            method: 'GET',
            url: targetConfig.apiUrl,
            withCredentials: false,
          });
          targetConfig.title =
            meta.data.article?.title || meta.data.section?.name;
        }),
      );

      this.setLinkData(linkData);
    },
  },
});
