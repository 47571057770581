<template>
  <DetailPage heading="PUBLISHING.DETAIL.PRODUCTS.SETTINGS_DIALOG.HEADING">
    <template #left-nav>
      <button class="link link-main icon-link" @click="modalInstance.dismiss">
        <Icon icon="left" /> {{ $t('GENERAL.BACK') }}
      </button>
    </template>
    <template #right-nav>
      <ProgressButton
        class="btn btn-primary save-btn"
        :on-click="save"
        label="GENERAL.SAVE"
        :disabled="invalidSettings"
      />
    </template>
    <template #content>
      <div v-if="initialized" class="product-settings">
        <ConfigSection
          heading="Assortment categories "
          subheading="What types of products should your design be printed on? You can select multiple."
          :error-text="
            invalidCategoriesSetting
              ? 'Please select at least one option'
              : null
          "
        >
          <ProductSettingsCategories
            :composition="composition"
            :selected-categories="categories"
            @on-select="onCategorySelection"
          />
        </ConfigSection>

        <ConfigSection v-if="showApparelSettings" heading="Apparel Settings">
          <ConfigSection
            heading="Target group"
            subheading="Who should wear your design?"
            display-inline
            secondary
            :error-text="
              invalidLabelsSetting ? 'Please select at least one option' : null
            "
          >
            <ProductSettingsTargetGroups
              :composition="composition"
              :selected-labels="labels"
              @on-select="onLabelSelection"
            />
          </ConfigSection>
          <div class="spacer"></div>
          <ConfigSection
            heading="PUBLISHING.DETAIL.PRODUCTS.DEFAULT_POSITION.HEADING"
            subheading="PUBLISHING.DETAIL.PRODUCTS.DEFAULT_POSITION.SELECTION_TEXT"
            display-inline
            secondary
          >
            <ProductSettingsHotspots
              :composition="composition"
              :selected-hotspot="hotspot"
              @on-select="onHotspotSelection"
            />
          </ConfigSection>
        </ConfigSection>

        <ConfigSection
          heading="PUBLISHING.DETAIL.PRODUCTS.DEFAULT_COLOR.HEADING"
          subheading="PUBLISHING.DETAIL.PRODUCTS.DEFAULT_COLOR.SELECTION_TEXT"
        >
          <ProductSettingsAppearanceGroups
            :composition="composition"
            :selected-appearance-group="appearanceGroup"
            @on-select="onAppearanceGroupSelection"
          />
        </ConfigSection>
        <ConfigSection
          heading="Missing something? Let us know."
          subheading="Do you have an idea for a new feature or notice a missing product category? Let us know! We’re always working to improve our partner area and appreaciate your feedback."
          secondary
        >
          <a :href="`mailto:${mail}`" class="link link-main icon-link">
            <Icon icon="speech-bubble" />{{ $t('Drop us a line') }}
          </a>
        </ConfigSection>
        <div class="spacer"></div>
      </div>
    </template>
  </DetailPage>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ProgressButton from '@/btnProgress/ProgressButton.vue';
import DetailPage from 'src/app/components/detailPage/DetailPage.vue';
import ConfigSection from 'src/app/components/publishing/ConfigSection.vue';
import ProductSettingsHotspots from './ProductSettingsHotspots.vue';
import ProductSettingsAppearanceGroups from './ProductSettingsAppearanceGroups.vue';
import ProductSettingsCategories from './ProductSettingsCategories.vue';
import ProductSettingsTargetGroups from './ProductSettingsTargetGroups.vue';
import compositionHelper from '@/composition/compositionHelper';
import contactData from '@/contactData/contactData';
import compositionCategoryHelper from '@/composition/compositionCategoryHelper';

export default {
  name: 'PublishingProductSettingsDialog',
  components: {
    ProgressButton,
    DetailPage,
    ConfigSection,
    ProductSettingsHotspots,
    ProductSettingsAppearanceGroups,
    ProductSettingsCategories,
    ProductSettingsTargetGroups,
  },
  props: {
    modalInstance: { type: Object, required: true },
    data: { type: Object },
  },
  data() {
    return {
      hotspot: null,
      appearanceGroup: null,
      categories: null,
      labels: null,
      initialized: false,
    };
  },
  computed: {
    ...mapState({
      composition: (s) => s.composition.current,
      currentScope: (s) => s.publishing.currentScope,
    }),
    mail() {
      return contactData.getMailForPublishingScope(this.currentScope);
    },
    showApparelSettings() {
      return compositionCategoryHelper.hasApparelCategory(this.categories);
    },

    invalidCategoriesSetting() {
      return !this.categories.length;
    },
    invalidLabelsSetting() {
      return !this.labels.length;
    },
    invalidSettings() {
      return this.invalidCategoriesSetting || this.invalidLabelsSetting;
    },
  },
  async created() {
    this.hotspot = compositionHelper.getDefaultHotspot(this.composition);
    this.appearanceGroup = compositionHelper.getDefaultAppearanceGroup(
      this.composition,
    )?.id;
    this.categories = compositionHelper.getCategories(this.composition);
    this.labels = compositionHelper.getApparelTargetGroupLabels(
      this.composition,
    );
    this.initialized = true;
  },
  methods: {
    ...mapActions({
      updateCompositionProductSettings:
        'composition/updateCompositionProductSettings',
    }),
    back() {},
    async save() {
      await this.updateCompositionProductSettings({
        defaultHotspot: this.hotspot,
        defaultAppearanceGroup: this.appearanceGroup,
        categories: this.categories,
        labels: this.labels,
      });

      this.modalInstance.close();
    },
    async onHotspotSelection(hotspot) {
      this.hotspot = hotspot;
    },
    async onAppearanceGroupSelection(appearanceGroup) {
      this.appearanceGroup = appearanceGroup;
    },
    async onCategorySelection(category) {
      const included = this.categories.some((cat) => cat.id === category.id);
      if (included) {
        this.categories = this.categories.filter(
          (cat) => cat.id !== category.id,
        );
      } else {
        this.categories.push(category);
      }
    },
    async onLabelSelection(label) {
      const included = this.labels.some((lab) => lab.id === label.id);
      if (included) {
        this.labels = this.labels.filter((lab) => lab.id !== label.id);
      } else {
        this.labels.push(label);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/links';

.product-settings {
  margin: 24px 0 0 0;
  padding: 0 24px;

  strong {
    display: inline-block;
    margin: 0 0 16px 0;
  }
}

.spacer {
  height: 24px;
}
</style>
