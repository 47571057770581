<template>
  <div>
    <SmartFaqLink :link="pricingLink" />
    <h4>
      <button type="button" class="all-btn" @click="showAllProductTypes">
        {{ $t('POS.SHOP_SETTINGS.PRICING.CATEGORIES') }}
      </button>
    </h4>
    <ul class="categories">
      <li v-for="category in categories" :key="category.id">
        <button
          type="button"
          class="category-btn"
          :class="{
            'category-btn--active': category === currentCategory,
          }"
          @click="showCategory(category)"
        >
          {{ category.names[locale] }}
        </button>
        <ul v-if="category === currentCategory" class="subcategories">
          <li v-for="subcategory in category.children" :key="subcategory.id">
            <button
              type="button"
              class="subcategory-btn"
              :class="{
                'subcategory-btn--active': subcategory === currentSubcategory,
              }"
              @click="showSubcategory(subcategory)"
            >
              {{ subcategory.names[locale] }}
            </button>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { PricingData } from 'src/app/partnerarea/pos/shop/shopSavable/PricingData';
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';
import smartFaqLinks from '@/smartFaq/smartFaqLinks';
import localeService from '@/localeService/localeService';

export default {
  name: 'PricingNav',
  components: {
    SmartFaqLink,
  },
  mixins: [PricingData],
  data() {
    return {
      currentCategory: null,
      currentSubcategory: null,
      pricingLink: smartFaqLinks.PRICING_HELP,
    };
  },
  computed: {
    config() {
      return this.$store.state.shop.shopSavable.clientData.config;
    },
    locale() {
      return localeService.getLocale();
    },
  },
  created() {
    this.showAllProductTypes();
  },
  beforeUnmount() {
    this.showAllProductTypes();
  },
  methods: {
    showCategory(category) {
      if (category === this.currentCategory) {
        this.currentCategory = null;
      } else {
        this.currentCategory = category;
        const categoryProductTypeIds = [];
        category.children.forEach((subcategory) =>
          categoryProductTypeIds.push(...subcategory.productTypeIds),
        );
        this.expandProductTypes([...new Set(categoryProductTypeIds)]);
      }
    },
    showSubcategory(subcategory) {
      this.currentSubcategory = subcategory;
      this.expandProductTypes(subcategory.productTypeIds);
    },
    showAllProductTypes() {
      const allProductTypeIds = [];
      this.currentCategory = null;
      this.currentSubcategory = null;
      this.categories.forEach((category) =>
        category.children.forEach((subcategory) =>
          allProductTypeIds.push(...subcategory.productTypeIds),
        ),
      );
      this.expandProductTypes(allProductTypeIds);
    },
    expandProductTypes(ptIds) {
      const expandedProductTypes = this.productTypes.filter((pt) =>
        ptIds.includes(parseInt(pt.id)),
      );
      this.$store.commit('shop/updateSectionOriginalData', {
        sectionKey: 'pricing',
        origData: { expandedProductTypes },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.pricingLink {
  margin-bottom: 16px;
  svg {
    width: 24px;
    height: 24px;
  }
}

.categories {
  margin-top: 1em;
  line-height: 2;
}

.subcategories {
  margin: 0.25em 0 1em 1em;
}

.all-btn,
.category-btn,
.subcategory-btn,
.save-btn,
.cancel-btn {
  padding: 0;
  background: none;
  border: 0;
  outline: none;

  &--active {
    color: $pa-color-main;
    font-weight: bold;
  }
}

.controls {
  display: flex;
  height: 60px;
  padding: 0 25px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background: #fff;
}

.save-btn {
  color: $pa-color-main;
}

.cancel-btn {
  margin-left: 1em;
  color: $sprd-color-darkest-grey;
}
</style>
