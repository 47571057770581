import smartFaqLinks from '@/smartFaq/smartFaqLinks';
import { useSmartFaqStore } from '@/stores/smartFaq';

export const articleKeys = {
  HELP_CENTER: 'HELP_CENTER',
  HELP_CENTER_SHOP: 'HELP_CENTER_SHOP',
  PRODUCT_ASSORTMENT: 'PRODUCT_ASSORTMENT',
  POSTER_TEMPLATES: 'POSTER_TEMPLATES',
  LEGAL_TIPS: 'LEGAL_TIPS',
  SPREAD_APP: 'SPREAD_APP',
  SUCCESS_TIPS: 'SUCCESS_TIPS',
  DESIGN_OCCASIONS: 'DESIGN_OCCASIONS',
  TOOL_BOX: 'TOOL_BOX',
  SELLING_GUIDE: 'SELLING_GUIDE',
  PREMIUM_DESIGN_SERVICE: 'PREMIUM_DESIGN_SERVICE',
  PRODUCT_RANGE_PAGE: 'PRODUCT_RANGE_PAGE',
  YOUTUBE_TUTORIALS: 'YOUTUBE_TUTORIALS',
};

export const pointOfSaleKeys = {
  ALL: 'ALL',
  MP: 'MP',
  SHOPS: 'SHOPS',
};

export const articles = [
  {
    key: articleKeys.SUCCESS_TIPS,
    headlineKey: 'TIPS_AND_TOOLS.ARTICLES.SUCCESS_TIPS.HEADLINE',
    descriptionKey: 'TIPS_AND_TOOLS.ARTICLES.SUCCESS_TIPS.DESCRIPTION',
    imageSrc: getImageSrc('success_tips.jpg'),
    pointOfSales: [pointOfSaleKeys.MP],
    tracking: 'mpTrends',
  },
  {
    key: articleKeys.SELLING_GUIDE,
    headlineKey: 'TIPS_AND_TOOLS.ARTICLES.SELLING_GUIDE.HEADLINE',
    descriptionKey: 'TIPS_AND_TOOLS.ARTICLES.SELLING_GUIDE.DESCRIPTION',
    imageSrc: getImageSrc('selling_guide.jpg'),
    pointOfSales: [pointOfSaleKeys.SHOPS],
    tracking: 'shopGuide',
  },
  {
    key: articleKeys.SPREAD_APP,
    headlineKey: 'TIPS_AND_TOOLS.ARTICLES.SPREAD_APP.HEADLINE',
    descriptionKey: 'TIPS_AND_TOOLS.ARTICLES.SPREAD_APP.DESCRIPTION',
    imageSrc: getImageSrc('spreadapp.jpg'),
    pointOfSales: [pointOfSaleKeys.MP, pointOfSaleKeys.SHOPS],
    path: '/account/deviceconnection',
    tracking: 'spreadapp',
  },
  {
    key: articleKeys.PRODUCT_ASSORTMENT,
    headlineKey: 'TIPS_AND_TOOLS.ARTICLES.PRODUCT_ASSORTMENT.HEADLINE',
    descriptionKey: 'TIPS_AND_TOOLS.ARTICLES.PRODUCT_ASSORTMENT.DESCRIPTION',
    imageSrc: getImageSrc('product_assortment.jpg'),
    pointOfSales: [pointOfSaleKeys.MP],
    tracking: 'mpProducts',
  },
  {
    key: articleKeys.PRODUCT_RANGE_PAGE,
    headlineKey: 'TIPS_AND_TOOLS.ARTICLES.PRODUCT_RANGE_PAGE.HEADLINE',
    descriptionKey: 'TIPS_AND_TOOLS.ARTICLES.PRODUCT_RANGE_PAGE.DESCRIPTION',
    imageSrc: getImageSrc('product_range_page_v2.jpg'),
    pointOfSales: [pointOfSaleKeys.SHOPS],
    tracking: 'shopProducts',
  },
  {
    key: articleKeys.DESIGN_OCCASIONS,
    headlineKey: 'TIPS_AND_TOOLS.ARTICLES.DESIGN_OCCASIONS.HEADLINE',
    descriptionKey: 'TIPS_AND_TOOLS.ARTICLES.DESIGN_OCCASIONS.DESCRIPTION',
    imageSrc: getImageSrc('design_occasions.jpg'),
    pointOfSales: [pointOfSaleKeys.MP],
    tracking: 'designOccasions',
  },
  {
    key: articleKeys.TOOL_BOX,
    headlineKey: 'TIPS_AND_TOOLS.ARTICLES.TOOL_BOX.HEADLINE',
    descriptionKey: 'TIPS_AND_TOOLS.ARTICLES.TOOL_BOX.DESCRIPTION',
    imageSrc: getImageSrc('tool_box.jpg'),
    pointOfSales: [pointOfSaleKeys.SHOPS],
    tracking: 'toolbox',
  },
  {
    key: articleKeys.PREMIUM_DESIGN_SERVICE,
    headlineKey: 'TIPS_AND_TOOLS.ARTICLES.PREMIUM_DESIGN_SERVICE.HEADLINE',
    descriptionKey:
      'TIPS_AND_TOOLS.ARTICLES.PREMIUM_DESIGN_SERVICE.DESCRIPTION',
    imageSrc: getImageSrc('premium_design_service.jpg'),
    pointOfSales: [pointOfSaleKeys.SHOPS],
    tracking: 'designservice',
  },
  {
    key: articleKeys.LEGAL_TIPS,
    headlineKey: 'TIPS_AND_TOOLS.ARTICLES.LEGAL_TIPS.HEADLINE',
    descriptionKey: 'TIPS_AND_TOOLS.ARTICLES.LEGAL_TIPS.DESCRIPTION',
    imageSrc: getImageSrc('legal_tips.jpg'),
    pointOfSales: [pointOfSaleKeys.MP, pointOfSaleKeys.SHOPS],
    smartFaqLink: smartFaqLinks.LEGAL_TIPS,
    tracking: 'legal',
  },
  {
    key: articleKeys.POSTER_TEMPLATES,
    headlineKey: 'TIPS_AND_TOOLS.ARTICLES.POSTER_TEMPLATES.HEADLINE',
    descriptionKey: 'TIPS_AND_TOOLS.ARTICLES.POSTER_TEMPLATES.DESCRIPTION',
    imageSrc: getImageSrc('poster_templates.jpg'),
    pointOfSales: [pointOfSaleKeys.MP],
    smartFaqLink: smartFaqLinks.POSTER_TEMPLATES,
    tracking: 'posters',
  },
  {
    key: articleKeys.YOUTUBE_TUTORIALS,
    headlineKey: 'TIPS_AND_TOOLS.ARTICLES.YOUTUBE_TUTORIALS.HEADLINE',
    descriptionKey: 'TIPS_AND_TOOLS.ARTICLES.YOUTUBE_TUTORIALS.DESCRIPTION',
    imageSrc: getImageSrc('youtube_tutorials_v2.jpg'),
    pointOfSales: [pointOfSaleKeys.SHOPS],
    tracking: 'shopYoutube',
  },
  {
    key: articleKeys.HELP_CENTER,
    headlineKey: 'TIPS_AND_TOOLS.ARTICLES.HELP_CENTER.HEADLINE',
    descriptionKey: 'TIPS_AND_TOOLS.ARTICLES.HELP_CENTER.DESCRIPTION',
    imageSrc: getImageSrc('help_center_mp.jpg'),
    pointOfSales: [pointOfSaleKeys.MP],
    smartFaqLink: smartFaqLinks.HELP_CENTER,
    tracking: 'mpHelp',
  },
  {
    key: articleKeys.HELP_CENTER_SHOP,
    headlineKey: 'TIPS_AND_TOOLS.ARTICLES.HELP_CENTER_SHOP.HEADLINE',
    descriptionKey: 'TIPS_AND_TOOLS.ARTICLES.HELP_CENTER_SHOP.DESCRIPTION',
    imageSrc: getImageSrc('help_center_shop.jpg'),
    pointOfSales: [pointOfSaleKeys.SHOPS],
    tracking: 'shopHelp',
  },
];

export const articleLinks = {
  EU: {
    [articleKeys.HELP_CENTER_SHOP]: {
      locales: {
        de: 'https://help.spreadshop.com/hc/de',
        da: 'https://help.spreadshop.com/hc/da',
        en: 'https://help.spreadshop.com/hc/en-gb',
        es: 'https://help.spreadshop.com/hc/es',
        fi: 'https://help.spreadshop.com/hc/fi',
        fr: 'https://help.spreadshop.com/hc/fr',
        it: 'https://help.spreadshop.com/hc/it',
        nl: 'https://help.spreadshop.com/hc/nl',
        no: 'https://help.spreadshop.com/hc/no',
        pl: 'https://help.spreadshop.com/hc/pl',
        sv: 'https://help.spreadshop.com/hc/sv',
      },
    },
    [articleKeys.PRODUCT_ASSORTMENT]: {
      locales: {
        de: 'https://www.spreadshirt.de/marktplatz-produkt-sortiment/produkte',
        da: 'https://www.spreadshirt.dk/markedsplads-produkt-sortiment/produkter',
        en: 'https://www.spreadshirt.co.uk/marketplace-product-selection/products',
        es: 'https://www.spreadshirt.es/platforma-producto-assortiment/productos',
        fi: 'https://www.spreadshirt.fi/markkinapaikka-tuote-sortiment/tuotteet',
        fr: 'https://www.spreadshirt.fr/marketplace-produits-assortiment/produits',
        it: 'https://www.spreadshirt.it/regali+marketplace-produits-assortiment',
        nl: 'https://www.spreadshirt.nl/marktplaats-product-sortiment/producten',
        no: 'https://www.spreadshirt.no/markedsplass-produkt-sortiment/produkter',
        pl: 'https://www.spreadshirt.pl/pasa%C5%BC-produkt-asortyment/listaproduktow',
        sv: 'https://www.spreadshirt.se/marknadsplats-produkt-sortiment/produkter',
      },
    },
    [articleKeys.SUCCESS_TIPS]: {
      locales: {
        de: 'https://blog.spreadshirt.net/de/design-trends/',
        fr: 'https://blog.spreadshirt.net/fr/tendances-designs/',
      },
      default:
        'https://blog.spreadshirt.net/uk/a-celebration-of-colour-design-trends/',
    },
    [articleKeys.DESIGN_OCCASIONS]: {
      locales: {
        de: 'https://blog.spreadshirt.net/de/die-wichtigsten-design-anlaesse',
        fr: ' https://blog.spreadshirt.net/fr/des-occasions-pour-vos-designs',
      },
      default: 'https://blog.spreadshirt.net/uk/your-holiday-calendar',
    },
    [articleKeys.TOOL_BOX]: {
      locales: {
        de: 'https://www.spreadshop.com/de/blog/toolbox',
        fr: 'https://www.spreadshop.com/fr/blog/boite-a-outils',
      },
      default: 'https://www.spreadshop.com/blog/toolbox',
    },
    [articleKeys.SELLING_GUIDE]: {
      locales: {
        de: 'https://www.spreadshop.com/de/blog/shop-handbuch',
        fr: 'https://www.spreadshop.com/fr/blog/guide-boutique',
      },
      default: 'https://www.spreadshop.com/blog/selling-guide',
    },
    [articleKeys.PRODUCT_RANGE_PAGE]: {
      locales: {
        de: 'https://www.spreadshop.com/de/partner-produkt-sortiment/produkte',
        fr: 'https://www.spreadshop.com/fr/gamme-de-produits',
        default: 'https://www.spreadshop.com/partner-product-range/products',
      },
    },
    [articleKeys.YOUTUBE_TUTORIALS]: {
      default: 'https://www.youtube.com/channel/UCrg-yR8QoADTfR_EaDwDfbg',
    },
  },
  NA: {
    [articleKeys.PRODUCT_ASSORTMENT]: {
      locales: {
        en: 'https://www.spreadshirt.com/marketplace-product-selection/products',
        fr: 'https://www.spreadshirt.ca/fr/marketplace-produits-assortiment/produits',
      },
    },
    [articleKeys.HELP_CENTER_SHOP]: {
      locales: {
        en: 'https://help.spreadshop.com/hc/en-us',
        fr: 'https://help.spreadshop.com/hc/fr-ca',
      },
    },
    [articleKeys.SUCCESS_TIPS]: {
      default:
        'https://blog.spreadshirt.net/us/a-celebration-of-colour-design-trends/',
    },
    [articleKeys.DESIGN_OCCASIONS]: {
      default: 'https://blog.spreadshirt.net/us/your-holiday-calendar/',
    },
    [articleKeys.TOOL_BOX]: {
      default: 'https://blog.spreadshirt.net/us/your-holiday-calendar',
    },
    [articleKeys.TOOL_BOX]: {
      default: 'https://www.spreadshop.com/blog/toolbox',
    },
    [articleKeys.SELLING_GUIDE]: {
      default: 'https://www.spreadshop.com/blog/selling-guide',
    },
    [articleKeys.PREMIUM_DESIGN_SERVICE]: {
      default: 'https://www.spreadshop.com/premiumdesign',
    },
    [articleKeys.PRODUCT_RANGE_PAGE]: {
      locales: {
        en: 'https://www.spreadshop.com/partner-product-range/products ',
        fr: 'https://www.spreadshop.com/fr/gamme-de-produits',
      },
    },
    [articleKeys.YOUTUBE_TUTORIALS]: {
      default: 'https://www.youtube.com/channel/UCrg-yR8QoADTfR_EaDwDfbg',
    },
  },
};

export function getHref(article, domain, language, platform) {
  if (article.smartFaqLink) {
    return useSmartFaqStore().relevantLinksByKey(article.smartFaqLink.key)
      ?.href;
  }

  const linkData =
    articleLinks[platform] && articleLinks[platform][article.key];

  if (!linkData) {
    return null;
  }

  if (linkData.locales && linkData.locales[language]) {
    return linkData.locales[language];
  } else {
    return linkData.default;
  }
}

export function getArticleByKey(articleKey) {
  return articles.find((article) => article.key === articleKey);
}

function getImageSrc(fileName) {
  return `images/tipsAndTools/articles/${fileName}`;
}
