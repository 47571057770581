<template>
  <HelpLink
    v-if="!headless && loaded"
    :translation-key="linkTitle"
    :icon-only="iconOnly"
    :hide-icon="hideIcon"
    href="#"
    @mouseover="onMouseOver"
    @mouseleave="onMouseLeave"
    @click.stop.prevent="onClick"
  />
</template>

<script>
import HelpLink from './HelpLink.vue';
import { mapMutations } from 'vuex';
import { mapActions } from 'pinia';

import analytics from '@/tracking/analytics';
import { useSmartFaqStore } from '@/stores/smartFaq';

export default {
  name: 'SmartFaqLink',
  components: {
    HelpLink,
  },
  props: {
    link: {
      type: [String, Object],
      required: true,
    },
    iconOnly: {
      type: Boolean,
    },
    hideIcon: {
      type: Boolean,
    },
    titleAsLabel: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    headless: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loaded: null,
      linkData: null,
      hovering: false,
    };
  },
  computed: {
    linkKey() {
      return this.link.key || this.link;
    },
    linkTitle() {
      let title;

      if (this.titleAsLabel) {
        title = this.linkData.title;
      } else if (this.title) {
        title = this.title;
      }

      return title;
    },
  },
  async created() {
    try {
      this.linkData = await this.addLink(this.linkKey);
      this.loaded = true;
    } catch (_) {
      // link might not be available for the current locale, not not show anything
    }
  },
  beforeUnmount() {
    this.removeLink(this.linkKey);
  },
  methods: {
    ...mapActions(useSmartFaqStore, [
      'addLink',
      'removeLink',
      'setHighlightLinkKey',
    ]),
    ...mapMutations({
      setHelpPanelExpansion: 'application/setHelpPanelExpansion',
      setHelpPanelHighlighted: 'application/setHelpPanelHighlighted',
    }),
    onClick() {
      this.setHighlightLinkKey(this.linkKey);
      this.setHelpPanelExpansion(true);

      analytics.logEvent('smart-faq-inline-link-click', {
        articleId: this.linkData?.articleId,
      });
    },
    onMouseOver() {
      if (!this.hovering) {
        this.setHelpPanelHighlighted(true);
      }
      this.hovering = true;
    },
    onMouseLeave() {
      if (this.hovering) {
        this.setHelpPanelHighlighted(false);
      }
      this.hovering = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.help-link.icon-only {
  .icon {
    margin-right: 0 !important;
  }
}
</style>
