<template>
  <Indicator
    :label="indicatorLabel"
    :hint-heading="indicatorHeading"
    :hint-body="body"
    :more-info-link="volumeCommissionHelpLink"
  />
</template>

<script>
import Indicator from 'src/app/components/indicator/Indicator.vue';
import smartFaqLinks from '@/smartFaq/smartFaqLinks';

export default {
  name: 'PerformanceIndicator',
  components: {
    Indicator,
  },
  props: {
    amount: {
      type: String,
    },
  },
  data() {
    return {
      label: this.$t('STATISTICS.SALE_DETAILS.PERFORMANCE.LABEL'),
      body: this.$t(
        this.amount
          ? 'STATISTICS.SALE_DETAILS.PERFORMANCE.INFO_AFTER_PAYOUT'
          : 'STATISTICS.SALE_DETAILS.PERFORMANCE.INFO',
      ),
      volumeCommissionHelpLink: smartFaqLinks.VOLUME_COMMISSION_HELP,
    };
  },
  computed: {
    indicatorHeading() {
      if (this.amount) {
        return `${this.label} - ${this.amount}`;
      } else {
        return this.label;
      }
    },
    indicatorLabel() {
      if (this.amount) {
        return `${this.label} : ${this.amount}`;
      } else {
        return this.label;
      }
    },
  },
};
</script>
