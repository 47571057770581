<template>
  <div>
    <div>
      <h4 class="manualIntegrationHeading">
        {{ $t('POS.SHOP_SETTINGS.FACEBOOK_MKT.MANUAL_INTEGRATION.HEADING') }}
      </h4>
      <ShopProductFeed
        subheading="POS.SHOP_SETTINGS.PRODUCT_FEED.FACEBOOK_BUSINESS_MGR_SUBHEADING"
        target-platform="facebook"
        :faq-link="faqLink"
        additional-help="POS.SHOP_SETTINGS.PRODUCT_FEED.FACEBOOK_BUSINESS_MGR_INFO"
      />
    </div>

    <div>
      <h4 class="manualIntegrationHeading">
        {{ $t('POS.SHOP_SETTINGS.FACEBOOK_MKT.DOMAIN_VERIFICATION_HEADING') }}
      </h4>
      <DomainVerification
        :model-value="settings.facebookDomainVerificationCode"
        regex="^[A-Za-z0-9]*$"
        subheading="POS.SHOP_SETTINGS.FACEBOOK_MKT.DOMAIN_VERIFICATION_SUBHEADING"
        label="POS.SHOP_SETTINGS.FACEBOOK_MKT.DOMAIN_VERIFICATION_LABEL"
        additional-help="POS.SHOP_SETTINGS.FACEBOOK_MKT.DOMAIN_VERIFICATION_INFO"
        help-link-target="https://www.facebook.com/business/help/286768115176155"
        @on-change="settings.facebookDomainVerificationCode = $event"
      />
    </div>

    <ShopTracking
      class="manual-facebook-pixel"
      :toggle-value="tracking.facebookEnabled"
      :tracking-id-value="tracking.facebookPixelId"
      toggle-label="POS.SHOP_SETTINGS.FACEBOOK_MKT.PIXEL_HEADING"
      input-label="SA.tracking.facebook.label"
      paragraph-text="POS.SHOP_SETTINGS.FACEBOOK_MKT.PIXEL_DESCRIPTION"
      additional-help="POS.SHOP_SETTINGS.FACEBOOK_MKT.PIXEL_INFO"
      regex="^[0-9]+$"
      @toggle-input="tracking.facebookEnabled = $event"
      @tracking-id-input="tracking.facebookPixelId = $event"
    />
  </div>
</template>

<script>
import ShopProductFeed from '../ShopProductFeed.vue';
import smartFaqLinks from '@/smartFaq/smartFaqLinks';
import ShopTracking from '../ShopTracking.vue';
import { TrackingData } from 'src/app/partnerarea/pos/shop/shopSavable/TrackingData';
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import DomainVerification from '../DomainVerification.vue';

export default {
  name: 'FacebookMarketing',
  components: {
    ShopProductFeed,
    ShopTracking,
    DomainVerification,
  },
  mixins: [TrackingData, SettingsData],
  data() {
    return {
      faqLink: smartFaqLinks.SELL_WITH_FACEBOOK,
    };
  },
  created() {
    this.$store.commit('shop/setLivePreviewVisible', true);
  },
};
</script>
